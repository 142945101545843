import { Link, useParams, useNavigate } from 'react-router-dom';
import useFetchData from '../../hooks/FetchData';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';
import DeliveryLocation from '../../components/DeliveryLocation';
import { useState } from 'react';
import { Button } from 'react-daisyui';
import { useGlobalContext } from '../../context/GlobalProvider';
import Error from '../../components/Error';
import DeliveryStatus from '../../components/DeliveryStatus';
import { format, parseISO } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';


function DeliveryDetailes() {
  const { t, i18n } = useTranslation()
  const { id } = useParams()
  const { token } = useGlobalContext()

  const fetchDelivery = (token) => {
    return fetch(`/api/deliveries/${id}`, {
      headers: {
        'authorization': `Bearer ${token}`
      }
    });
  };
  const { data, isLoading } = useFetchData(fetchDelivery);

  const [error, setError] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const navigate = useNavigate();

  const deleteDlivery = async () => {
    setIsDeleting(true);
    try {
      const response = await fetch(`/api/deliveries/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 204) {
        setError('');
        navigate('../', { replace: true });
      } else {
        const json = await response.json();
        if (response.status === 500) {
          setError('unexpected');
          return;
        }
        if (!response.ok) {
          setError(json.error);
          return;
        }
      }
    } catch (error) {
      setError('unexpected');
    } finally {
      setIsDeleting(false);
    }
  };

  const getLocale = () => {
    switch (i18n.language) {
      case 'fr':
        return fr;
      case 'en':
      default:
        return enUS;
    }
  };

  return (
    <div>
      <div className='flex text-lg items-center px-2 md:px-6 w-min hover:underline font-medium'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 me-2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
        </svg>
        <Link to="../">{t("delivery.Deliveries")}</Link>
      </div>

      {isLoading || (data && Object.keys(data).length === 0)
        ?
        <Loading heigh="h-[75vh]" />
        :
        <>
          <div className="flex flex-col gap-y-4 md:flex-row md:justify-between px-4 md:px-6 py-8">

            <div className='flex items-center gap-4'>
              <div className='text-4xl font-medium'>{data.idsRef}</div>
              <DeliveryStatus
                status={data.status}
              />
            </div>

            {data.status === "AVAILABLE" && <div className='flex-1 text-right'>
              <Link to="update" className='btn btn-primary rounded-2xl'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                </svg>
                {t("commons.Update")}
              </Link>
            </div>}

            {data.status === "DELIVERED" && <div className='flex-1 text-right'>
              <button className="btn btn-primary" onClick={() => document.getElementById('location-modal').showModal()}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                  <path fillRule="evenodd" d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clipRule="evenodd" />
                </svg>
              </button>
            </div>}

          </div>

          <div className='flex flex-col xl:flex-row gap-6 py-10 px-2 md:px-6'>

            <div className='flex flex-col xl:w-1/3'>
              <div className='border rounded-2xl pt-6 bg-base-100'>
                <div className='flex items-center gap-4 px-6'>
                  <div className='p-2 rounded-full shadow-xl'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                    </svg>
                  </div>
                  <div className='text-xl font-medium'>
                    {t("commons.Info")}
                  </div>
                </div>
                <div className='pt-3 mt-3' >
                  <div className='border-b px-8 mt-2'>
                    <p className='opacity-75 my-1'>{t("commons.Organisation")}</p>
                    <p className='font-medium my-1'>{data.organizationName}</p>
                  </div>
                  {data.startDate && <div className='border-b px-8 mt-2'>
                    <p className='opacity-75 my-1'>{t("commons.Depart Hour")}</p>
                    <p className='badge badge-ghost my-1'>{data.startDate && format(parseISO(data.startDate), 'PPp', { locale: getLocale() })}</p>
                  </div>}
                  {data.endDate && <div className='border-b px-8 mt-2'>
                    <p className='opacity-75 my-1'>{t("delivery.Arrive Hour")}</p>
                    <p className='badge badge-ghost mt-1'>{data.endDate && format(parseISO(data.endDate), 'PPp', { locale: getLocale() })}</p>
                  </div>}
                  {data.deliveryPersonName && <div className='border-b px-8 mt-2'>
                    <p className='opacity-75 my-1'>{t("commons.delivery agent")}</p>
                    <p className='font-medium my-1'>{data.deliveryPersonName}</p>
                  </div>}
                  <div className='px-8 mt-2'>
                    <p className='opacity-75 my-1'>{t("commons.Client")}</p>
                    <p className='font-medium my-1 mb-3'>{data.clientName}</p>
                  </div>
                </div>
              </div>

              {data.status !== "IN_PROGRESS" && <div className='border rounded-2xl p-6 mt-6'>
                <div className='flex items-center gap-4'>
                  <div className='p-2 rounded-full shadow-lg'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
                    </svg>
                  </div>
                  <div className='text-xl font-medium'>
                    {t("commons.Security")}
                  </div>
                </div>
                <div className='mt-7 mb-1'>
                  <Button loading={isDeleting} disabled={isDeleting} className='btn btn-error text-base-100' onClick={() => document.getElementById('modal-2').showModal()}>{t("delivery.Delete delivery")}</Button>
                  <div className='text-sm opacity-75 mt-4'>
                    {t("delivery.delete-warning")}
                  </div>
                  {error &&
                    <Error error={error} absolute={true} />
                  }
                </div>
              </div>}
            </div>

            <div className='flex flex-col gap-y-6 xl:w-2/3'>
              <div className='border h-min rounded-2xl pt-6'>
                <div className='flex items-center gap-4 px-6'>
                  <div className='p-2 rounded-full shadow-lg'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75" />
                    </svg>
                  </div>
                  <div className='text-xl font-medium'>
                    {t("delivery.Details")}
                  </div>
                </div>
                <div className='pt-3 mt-3'>
                  {data.description && <div className='border-b px-8 mt-2'>
                    <p className='opacity-75 my-1'>{t("delivery.Description")}</p>
                    <p className='font-medium my-1'>{data.description}</p>
                  </div>}
                  {data.message && <div className='border-b px-8 mt-2'>
                    <p className='opacity-75 my-1'>{t("delivery.Message")}</p>
                    <p className='font-medium my-1'>{data.message}</p>
                  </div>}
                  {data.coolerReturned && <div className='border-b px-8 mt-2'>
                    <p className='opacity-75 my-1'>{t("delivery.Cooler returned")}</p>
                    <p className='font-medium my-1'>{data.coolerReturned ? t("delivery.yes") : t("delivery.no")}</p>
                  </div>}
                  <div className='px-8 mt-2'>
                    <p className='opacity-75 my-1'>{t("delivery.Distance")}</p>
                    <p className='font-medium my-1 mb-3'>{data.distance} km</p>
                  </div>
                </div>
              </div>
              <div>
                {data.hasSignature && <div className="avatar lg:m-8">
                  <div className="w-full md:w-5/12 rounded-lg hover:cursor-pointer" onClick={() => document.getElementById('modal').showModal()}>
                    <img src={`${process.env.REACT_APP_API_URL}/deliveries/${data.id}/signature`} alt="client" />
                  </div>
                </div>}
              </div>
              <div className='flex flex-col md:flex-row items-center gap-x-4 gap-y-2 w-full lg:w-2/3'>
                {data.hasImage1 && <div className="avatar w-full md:w-1/2">
                  <div className="rounded-lg hover:cursor-pointer" onClick={() => document.getElementById('modal-image-1').showModal()}>
                    <img src={`${process.env.REACT_APP_API_URL}/deliveries/${data.id}/image-1`} alt="return-1" />
                  </div>
                </div>}
                {data.hasImage2 && <div className="avatar w-full md:w-1/2">
                  <div className="rounded-lg hover:cursor-pointer" onClick={() => document.getElementById('modal-image-2').showModal()}>
                    <img src={`${process.env.REACT_APP_API_URL}/deliveries/${data.id}/image-2`} alt="return-2" />
                  </div>
                </div>}
              </div>
            </div>


          </div>
        </>}

      {data.hasSignature && <dialog id="modal" className="modal">
        <div className="modal-box p-0 h-max rounded-sm">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-neutral absolute right-2 top-2">✕</button>
          </form>
          <img alt="lv-pic" src={`${process.env.REACT_APP_API_URL}/deliveries/${data.id}/signature`} className="w-full h-auto cursor-default" />
        </div>
      </dialog>}

      <dialog id="modal-2" className="modal">
        <div className="modal-box flex flex-col gap-y-7 px-6 pt-4pb-2 h-max rounded-2xl border-4 border-error">
          <div className='flex items-center gap-4'>
            <div className='p-2 rounded-full shadow-lg'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
              </svg>
            </div>
            <div className="text-xl font-medium">
              {t("commons.Security")}
            </div>
          </div>
          <div className='font-medium text-[18px]'>{t("commons.delete-confirmation")}</div>
          <form method="dialog" className='flex justify-end items-center gap-x-2'>
            <button className="btn btn-error" onClick={deleteDlivery}>{t("commons.delete")}</button>
            <button className="btn btn-ghost">{t("commons.cancel")}</button>
          </form>
        </div>
      </dialog>

      {data.longitude && data.latitude &&
        /* location modal */
        <dialog id="location-modal" className="modal modal-bottom sm:modal-middle">
          <div className="modal-box relative p-0">
            <DeliveryLocation long={data.longitude} lat={data.latitude} long2={data.organizationLongitude} lat2={data.organizationLatitude} />
          </div>
          <form method="dialog" className="modal-backdrop">
            <button>close</button>
          </form>
        </dialog>
      }

      {data.hasImage1 && <dialog id="modal-image-1" className="modal">
        <div className="modal-box p-0 h-max rounded-sm">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-neutral absolute right-2 top-2">✕</button>
          </form>
          <img alt="lv-pic" src={`${process.env.REACT_APP_API_URL}/deliveries/${data.id}/image-1`} className="w-full h-auto cursor-default" />
        </div>
      </dialog>}

      {data.hasImage2 && <dialog id="modal-image-2" className="modal">
        <div className="modal-box p-0 h-max rounded-sm">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-neutral absolute right-2 top-2">✕</button>
          </form>
          <img alt="lv-pic" src={`${process.env.REACT_APP_API_URL}/deliveries/${data.id}/image-2`} className="w-full h-auto cursor-default" />
        </div>
      </dialog>}

    </div>
  )
}

export default DeliveryDetailes